// @flow
import React from 'react';

export default () => (
  <footer className="footer">
    <div className="footer-copyright">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="float-right text-right">
              <span className="copyright">© 2018 CS Frequency Limited</span>
            </div>
            <ul className="social-icons float-left">
              <li>
                <a href="http://twitter.com/csfrequency">
                  <i className="fab fa-twitter" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
