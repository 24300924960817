// @flow
import React from 'react';
import { Link as ScrollLink, animateScroll as scroller } from 'react-scroll';

type Props = {};
type State = {
  visible: boolean,
};

export default class ScrollTop extends React.Component<Props, State> {
  state = {
    visible: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { visible } = this.state;
    return (
      <ScrollLink
        className={`scroll-top ${visible ? 'scroll-top-visible' : ''}`}
        duration={500}
        to="top"
      >
        <i className="fa fa-angle-up" />
      </ScrollLink>
    );
  }

  handleScroll = () => {
    this.setState({
      visible: window.pageYOffset >= 100,
    });
  };

  scrollToTop = e => {
    e.preventDefault();
    scroller.scrollTo('top', {
      duration: 20000,
    });
  };
}
