// @flow
import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import logo from '../../assets/images/logo-45@3x.png';

type Props = {};
type State = {
  showMobileMenu: boolean,
  small: boolean,
};

export default class Header extends React.Component<Props, State> {
  state = {
    showMobileMenu: false,
    small: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { showMobileMenu, small } = this.state;
    return (
      <header
        className={`header header-right ${
          small ? 'header-small header-shadow' : ''
        }`}
      >
        <div className="container-fluid">
          <div className="inner-header">
            <GatsbyLink className="inner-brand" to="/">
              <img className="brand-dark" src={logo} alt="" />
            </GatsbyLink>
          </div>
          <div
            className={`inner-navigation collapse ${
              showMobileMenu ? 'show' : ''
            }`}
          >
            <div className="inner-navigation-inline">
              <div className="inner-nav">
                <ul>
                  <li>
                    <GatsbyLink to="/">Home</GatsbyLink>
                  </li>
                  <li>
                    <GatsbyLink to="/web-mobile-development/">
                      Web and Mobile
                    </GatsbyLink>
                  </li>
                  <li>
                    <GatsbyLink to="/cloud-development/">Cloud</GatsbyLink>
                  </li>
                  <li>
                    <GatsbyLink to="/products/">Products</GatsbyLink>
                  </li>
                  <li>
                    <GatsbyLink to="/about-us/">About</GatsbyLink>
                  </li>
                  <li>
                    <GatsbyLink className="btn btn-lg btn-brand" to="/hire-us/">
                      Hire Us
                    </GatsbyLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="nav-toggle">
            <a href="#" onClick={this.toggleMobileMenu}>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </a>
          </div>
        </div>
      </header>
    );
  }

  handleScroll = () => {
    this.setState({
      small: window.pageYOffset >= 20,
    });
  };

  toggleMobileMenu = event => {
    event.preventDefault();
    this.setState(state => ({
      showMobileMenu: !state.showMobileMenu,
    }));
  };
}
