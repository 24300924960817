// @flow
import React from 'react';
import { Helmet } from 'react-helmet';
import { CSSTransition } from 'react-transition-group';

import Footer from './Footer';
import Header from './Header';
import ScrollTop from './ScrollTop';

import '../../assets/css/template.css';
import '../../assets/css/template-cs.css';

type Props = {
  children: any,
};

const Meta = () => (
  <Helmet>
    <title>CS Frequency - Web, Mobile and Cloud Experts</title>
    <meta
      name="description"
      content="Assisting you with your journey to the cloud"
    />
    <meta
      name="keywords"
      content="web development, mobile app development, cloud development, cloud architecture, cloud consultancy, android app development, ios app development, google cloud platform consultant, microsoft azure consultant, amazon web services consultant"
    />
    <meta property="og:type" content="website" />
    <meta property="og:site_name" content="CS Frequency" />
    <meta
      property="og:title"
      content="CS Frequency - Web, Mobile and Cloud Experts"
    />
    <meta
      property="og:description"
      content="Assisting you with your journey to the cloud."
    />
    {/* TODO
    <meta
      property="og:image"
      content="https://csfrequency.com/media/og_launch_1200.jpg"
    />
    */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@csfrequency" />
    <meta
      name="twitter:title"
      content="CS Frequency | Web, Mobile and Cloud Experts"
    />
    <meta
      name="twitter:description"
      content="Assisting you with your journey to the cloud."
    />
    {/* TODO
    <meta
      name="twitter:image"
      content="https://mybabybio.com/media/og_launch_1200.jpg"
    />
    */}
  </Helmet>
);

export default ({ children }: Props) => (
  <CSSTransition
    in
    appear
    timeout={1}
    classNames={{
      appearActive: 'fade-in',
      enterActive: 'fade-in',
      enterDone: 'fade-in',
    }}
  >
    <div className="layout">
      <Meta />
      <Header />
      <div id="top" className="wrapper">
        {children}
        <Footer />
        <ScrollTop />
      </div>
    </div>
  </CSSTransition>
);
